



















































import { FormModal } from '@/app/components';
import { computed, defineComponent } from '@vue/composition-api';
import { AssetTypeId } from '../../asset/constants/asset-type-id.constants';

export default defineComponent({
    name: 'DerivedAssetsModal',
    components: {
        FormModal,
    },
    props: {
        derivedAssets: {
            type: Array,
            required: true,
        },
        derivedWorkflows: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const derivedModels = computed(() =>
            props.derivedAssets.filter((asset: any) => asset.assetTypeId === AssetTypeId.Model),
        );

        const derivedResults = computed(() =>
            props.derivedAssets.filter((asset: any) => asset.assetTypeId === AssetTypeId.Result),
        );

        return {
            derivedModels,
            derivedResults,
        };
    },
});
